import React, { useState } from 'react'

import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/client'

import { Container, Row, Col } from 'styled-bootstrap-grid'

import Table from '../components/Table'
import Button from '../components/Button'
import Title from '../components/Title'
import Error from '../components/Error'

import FlexBox from '../components/FlexBox'
import Spacing from '../components/Spacing'
import Typography from '../components/Typography'

import { Field, Form } from 'react-final-form'
import DatePickerField from '../components/DatePickerField'

const UTILITIES_REPORT = gql`
  query utilitiesReport($start: String, $end: String) {
    utilitiesReport(start: $start, end: $end)
  }
`

export default function PeopleList() {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState([])

  const [utilitiesReport, { data, loading, error }] = useLazyQuery(
    UTILITIES_REPORT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    }
  )

  if (error) return <Error error={error} />

  let tableData = []

  if (data && data.utilitiesReport) {
    tableData = Object.entries(data.utilitiesReport).map(([key, value]) => {
      return {
        name: <Typography variant="bodySmall">{key}</Typography>,
        number: (
          <Typography variant="bodySmall" component="a" colour="brand" nowrap>
            {value}
          </Typography>
        ),
      }
    })
  }

  return (
    <>
      <Title title={'Changed Home'} lite />
      <Container>
        <Row justifyContent="center">
          <Col col xl="12">
            <Table
              title={
                <Form
                  onSubmit={(values) => {
                    utilitiesReport({ variables: values })
                  }}
                  initialValues={{}}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <FlexBox justifyContent="space-between">
                        <Field
                          name="start"
                          label="Start"
                          component={DatePickerField}
                        />
                        <Spacing multiplier={4} />
                        <Field
                          name="end"
                          label="End"
                          component={DatePickerField}
                        />
                        <Spacing multiplier={4} />

                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            submitting
                            // || pristine
                          }
                          type="submit"
                        >
                          Search
                        </Button>
                      </FlexBox>
                      {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    </form>
                  )}
                />
              }
              headers={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  disableFilters: true,
                },
                {
                  Header: 'Number',
                  accessor: 'number',
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  accessor: 'actions',
                  disableSortBy: true,
                  disableFilters: true,
                },
              ]}
              data={tableData}
              loading={loading}
              updatePageIndex={setPageIndex}
              updatePageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
              sortBy={sortBy}
              updateSortBy={setSortBy}
              pageCount={data?.getPeople?.pageCount}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
